@import 'Styles/includes';

.CardEntrance {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background: $red-5;

    &--Small {
        box-shadow: 0 4px 18px 0 rgba(black, .14);
    }

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    }

    &__Image {
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 60%;
        }

        #{$root}--HasImage &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: black;
            opacity: .05;
        }

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }

    &__Container {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;
        overflow: hidden;

        #{$root}--Large & {
            padding: 22px 64px 23px 16px;

            @include media(ML) {
                padding: 28px 72px 29px 24px;
            }
        }

        #{$root}--Large#{$root}--HasImage & {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        #{$root}--Small & {
            padding: 15px 64px 15px 16px;

            @include media(ML) {
                padding: 16px 72px 16px 24px;
            }
        }

        #{$root}:hover & {
            color: white;

            &::before {
                transform: translateY(-50%) scale(1);
            }

            @media(hover: none) {
                color: $grey-90;

                &::before {
                    transform: translateY(-50%) scale(0);
                }
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 24px;
            margin: auto;
            width: 32px;
            height: 32px;
            right: calc(-100% + 40px);
            width: 200%;
            height: 0;
            padding-bottom: 200%;
            border-radius: 50%;
            background: $red;
            transform: translateY(-50%) scale(0);
            transform-origin: center center;
            transition: transform .5s ease-in-out;
        }
    }

    &__Content {
        position: relative;
    }

    &__Title {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.56;
        transition: color .4s .1s ease-in-out;

        #{$root}--Large & {
            @include media(M) {
                font-size: 2rem;
            }
        }
    }

    &__Text {
        display: none;
        margin-top: 6px;
        font-size: 1.6rem;
        line-height: 1.5;
        transition: color .4s .1s ease-in-out;

        #{$root}--Small & {
            display: block;
        }
    }

    &__Button {
        @extend %button--round-link-arrow;

        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        right: 24px !important;
        margin: auto !important;

        #{$root}--Large & {
            @extend %button--round-link-arrow-large;
        }
    }

    &__Link {
        @include href-external() {
            + #{$root}__Container #{$root}__Button,
            + #{$root}__Image + #{$root}__Container #{$root}__Button {
                @extend %button--round-link-external;
            }

            #{$root}--Large & + #{$root}__Container #{$root}__Button,
            #{$root}--Large & + #{$root}__Image + #{$root}__Container #{$root}__Button {
                @extend %button--round-link-external-large;
            }
        }
    }
}
