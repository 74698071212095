@import 'Styles/includes';

.Entrances {
    $root: &;

    @include wrap;

    position: relative;
    margin-top: 48px;
    margin-bottom: 48px;

    @include media(M) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-bottom: 80px;
    }

    &--Overlap {
        margin-top: -44px;

        @include media(ML) {
            margin-top: -48px;
        }
    }

    &__List {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -16px;
        margin-bottom: -16px;

        @include media(ML) {
            margin-left: -24px;
            margin-bottom: 0;
        }

        @include media(L) {
            margin-left: -38px;
        }
    }

    &__Item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-bottom: 16px;
        width: calc(100% - 16px);
        min-height: 88px;

        @include media(ML) {
            margin-left: 24px;
            margin-bottom: 0;
            min-height: 96px;
        }

        @include media(L) {
            margin-left: 38px;
        }

        #{$root}--Third & {
            @include media(M) {
                width: calc(#{percentage(1/3)} - 16px);
            }

            @include media(ML) {
                width: calc(#{percentage(1/3)} - 24px);
            }

            @include media(L) {
                width: calc(#{percentage(1/3)} - 38px);
            }
        }

        #{$root}--Fourth & {
            @include media(M) {
                width: calc(50% - 16px);
            }

            @include media(ML) {
                width: calc(25% - 24px);
            }

            @include media(L) {
                width: calc(25% - 38px);
            }
        }

        #{$root}--Small & {
            min-height: 88px;

            @include media(ML) {
                min-height: 96px;
            }
        }
    }
}
